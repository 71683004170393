<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div class="upgrade-margin">
    <div class="custom-text">
      <span class="user-manage">{{ $t('ProductManage.ModelManage.productManage') }} &nbsp;/&nbsp;</span>
      {{ $t('ProductManage.ModelManage.modelManage') }}
    </div>

    <div class="all-tab">
      <el-tabs v-model="activeTab" class="custom-tabs">
        <el-tab-pane :label=" $t('ProductManage.ModelManage.motor')" name="motor" @click="changeTab('motor')">
          <AsyncMotorPage v-if="activeTab === 'motor'"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('ProductManage.ModelManage.dashboard')" name="dashboard"
                     @click="changeTab('dashboard')">
          <DashboardTable :data="dashboardData" v-if="activeTab === 'dashboard'"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>
import DashboardTable from "./components/DashboardPage.vue";

import {defineAsyncComponent} from 'vue';

const AsyncMotorPage = defineAsyncComponent(() =>
    import(/* webpackChunkName: "motor-page" */ './components/MotorPage.vue')
);

export default {
  name: 'ModelManagement',
  components: {
    AsyncMotorPage,
    DashboardTable,
  },
  data() {
    return {
      activeTab: "motor",
      activeTextColor: 'green', // 选中标签文字的颜色
      barActiveColor: 'green', // 切换横条的颜色

      motorSearchText: "",
      dashboardSearchText: "",
      motorData: [], // 电机数据
      motorTotal: 0, // 电机总记录数
      motorCurrentPage: 1,
      dashboardData: [], // 仪表盘数据
      dashboardTotal: 0, // 仪表盘总记录数
      dashboardCurrentPage: 1,

      totalRecords: 0, // 数据总数
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
    };
  },
  methods: {
    // 处理分页变化事件
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    // 处理每页显示条数变化事件
    handlePageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
    },
    changeTab(tabName) {
      this.activeTab = tabName; // 当选项卡被点击时更新选中的选项卡
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/publicStyle.css";

.custom-tabs {
  border-color: red !important;
}

.all-tab {
  margin-left: 16px;
  color: rgb(0, 154, 68);
}

</style>
  