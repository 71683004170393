<template>
  <div class="add-motor">
    <el-button class="button-background-color" @click="showAddDialog">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;{{ $t('ProductManage.ModelManage.add') }}
    </el-button>

    <el-dialog
        style="text-align: left;"
        :title="$t('ProductManage.ModelManage.add')"
        v-model="addDialogVisible"
        width="500"
        @close="handleDialogClose"
    >
      <hr class="top-separator"/>

      <!-- 弹窗内容 -->
      <el-form :model="motorForm" ref="motorForm" :rules="motorFormRules" label-width="150px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ModelManage.hardwareVersionNumber')" prop="hardwareVersion">
              <el-input v-model="motorForm.hardwareVersion"
                        :placeholder="$t('ProductManage.ModelManage.hardwareVersionPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="isMotorTable">

            <el-form-item label="" prop="versionType" class="custom-radio-group">
              <el-radio-group v-model="motorForm.versionType">
                <el-radio :label="0"> {{ $t('ProductManage.ModelManage.hardwareVersion') }}</el-radio>
                <el-radio :label="1"> {{ $t('ProductManage.ModelManage.parameterVersion') }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ModelManage.hardwareVersionNumberFirst4')" prop="softwareVersion"
                          v-if="motorForm.versionType === 0 ">
              <el-input v-model="motorForm.softwareVersion"
                        :placeholder="$t('ProductManage.ModelManage.hardwareVersionNumberFirst4Placeholder')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <!-- 参数版本对应的输入框 -->
            <el-form-item :label="$t('ProductManage.ModelManage.parameterVersionNumber')" prop="parameterVersion"
                          v-if="motorForm.versionType === 1 ">
              <el-input v-model="motorForm.parameterVersion"
                        :placeholder="$t('ProductManage.ModelManage.parameterVersionNumberPlaceholder')"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="isMotorTable">
            <el-form-item prop="motorType">
              <label> <span style="color: rgb(245,108,108)">*</span>
                {{ $t('ProductManage.ModelManage.motorType') }}</label>
              <el-select v-model="motorForm.motorType" filterable
                         :placeholder="$t('ProductManage.ModelManage.motorTypePlaceholder')" style="width: 100%;">
                <el-option :label="$t('ProductManage.centerMotor')" value="0"></el-option>
                <el-option :label="$t('ProductDiagnosis.wheelMotor')" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ModelManage.model')" prop="model">
              <el-input v-model="motorForm.model"
                        :placeholder="$t('ProductManage.ModelManage.modelPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ModelManage.protocolType')" prop="protocolType"
                          class="custom-radio-group">
              <el-radio-group v-model="motorForm.protocolType"
                              :placeholder="$t('ProductManage.ModelManage.protocolTypePlaceholder')">
                <el-radio :label="0">UART</el-radio>
                <el-radio :label="1">CAN</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('ProductManage.ModelManage.description')" prop="description">
              <el-input type="textarea" v-model="motorForm.description"
                        :placeholder="$t('ProductManage.ModelManage.descriptionPlaceholder')" :rows="3"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <hr class="dialog-separator"/>
      <div class="dialog-footer">
        <el-button @click="cancelAddDialog" class="custom-reset-button">
          {{$t('ProductManage.ModelManage.cancel')}}
        </el-button>
        <el-button class="button-background-color" @click="addMotorData" :loading="isButtonLoading"> {{
            $t('ProductManage.ModelManage.confirm')
          }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";
import {addProductModels} from "@/api/api";
import dialogMixin from '@/mixins/dialogMixin';

export default {
  components: {Plus},
  mixins: [dialogMixin],
  data() {
    return {
      addDialogVisible: false,
      motorForm: {
        hardwareVersion: '',
        parameterVersion: '',
        parameterVersionNumber: '',
        softwareOrParameterVersion: '',
        softwareVersion: '',
        motorType: '',
        model: '',
        protocolType: '',
        description: '',
        versionType: 0, // 设置默认值为 "软件版本"
      },
      motorFormRules: {
        hardwareVersion: [
          {required: true, message: this.$t('ProductManage.hardwareVersionRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.hardwareVersionPattern'),
            trigger: 'blur',
          },
        ],
        parameterVersion: [
          {
            required: true,
            message: this.$t('ProductManage.parameterVersionRequired'),
            trigger: 'change'
          },
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.parameterVersionPattern'),
            trigger: 'blur',
          },
        ],
        softwareVersion: [
          {required: true, message: this.$t('ProductManage.softwareVersionRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]{4}$/,
            message: this.$t('ProductManage.softwareVersionPattern'),
            trigger: 'blur',
          },
        ],
        motorType: [
          {required: true, message: this.$t('ProductManage.motorTypeRequired'), trigger: 'change'},
        ],
        model: [
          {required: true, message: this.$t('ProductManage.pleaseEnterModel'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.modelPattern'),
            trigger: 'blur',
          },
        ],
        protocolType: [
          {
            required: true,
            message: this.$t('ProductManage.protocolTypeRequired'),
            trigger: 'change'
          },
        ],
      },
    };
  },
  props: {
    isMotorTable: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleDialogClose() {
      this.resetForm();
    },
    showAddDialog() {
      this.addDialogVisible = true;
    },
    resetForm() {
      this.$refs.motorForm.resetFields();
    },
    async addMotorData() {
      await this.$refs.motorForm.validate(async (valid) => {
        if (valid) {
          await this.handleButtonAction(async () => {

            const formData = {
              type: this.isMotorTable ? 0 : 1,
              hardware_version: this.motorForm.hardwareVersion,
              version_type: this.motorForm.versionType,
              software_version: this.motorForm.softwareVersion,
              config_version: this.motorForm.parameterVersion,

              motor_type: parseInt(this.motorForm.motorType),
              code: this.motorForm.model,
              protocol_type: this.motorForm.protocolType,
              desc: this.motorForm.description,
            };

            await addProductModels(formData);

            // 添加数据成功后，关闭弹窗
            this.addDialogVisible = false;

            // 清空表单数据
            this.resetForm();

            await this.loadData();
          });
        }
      });
    },
    cancelAddDialog() {
      // Close the dialog
      this.addDialogVisible = false;

      // 清空表单数据
      this.motorForm = {
        hardwareVersion: '',
        softwareVersion: '',
        motorType: null,
        model: '',
        protocolType: '',
        description: '',
        softwareOrParameterVersion: '',
        versionType: null,
      };
    },
  },
};
</script>

<style scoped>

/* 默认样式，适用于大屏幕 */
.custom-dialog-width {
  width: 10%; /* 大屏幕上保持原始设置 */
  max-width: 300px; /* 添加最大宽度限制 */
}

/* 非常大的屏幕（大型桌面） */
@media (min-width: 1200px) {
  .custom-dialog-width {
    width: 10%; /* 在非常大的屏幕上设置适中的宽度 */
  }
}

/* 大屏幕（普通桌面） */
@media (min-width: 992px) and (max-width: 1199px) {
  .custom-dialog-width {
    width: 10%; /* 在大屏幕上略微增加宽度 */
    max-width: 600px; /* 添加最大宽度限制 */
  }
}

/* 中屏幕（小型桌面或大型平板） */
@media (min-width: 768px) and (max-width: 991px) {
  .custom-dialog-width {
    width: 10%; /* 在中屏幕上进一步增加宽度 */
  }
}

/* 小屏幕（小型平板） */
@media (min-width: 576px) and (max-width: 767px) {
  .custom-dialog-width {
    width: 10%; /* 在小屏幕上使宽度更大 */
  }
}

/* 非常小的屏幕（手机） */
@media (max-width: 575px) {
  .custom-dialog-width {
    width: 10%; /* 在非常小的屏幕上最大化宽度 */
  }
}

/* 去除弹框内容的内边距 */
:deep(.el-dialog__body){
  padding-bottom: 0; /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep(.el-dialog__body){
  padding: 20px 15px 5px 20px; /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep( .el-dialog){
  padding: 0; /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

:deep( .custom-radio-group .el-radio__input.is-checked .el-radio__inner){
  border-color: rgb(0, 154, 68) !important;
  background-color: white !important;
}

:deep(.custom-radio-group .el-radio__input.is-checked .el-radio__inner::after){
  background-color: rgb(0, 154, 68) !important;
  width: 8px; /* 增加中间点的宽度 */
  height: 8px; /* 增加中间点的高度 */
  transform: translate(-50%, -50%); /* 确保点居中 */
}

:deep( .custom-radio-group .el-radio__label) {
  color: rgb(38, 38, 38); /* 黑色 */
}

:deep(.el-form-item__label) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}

.add-motor {
  text-align: left;
  margin: 15px 15px 5px 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
