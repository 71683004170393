<template>
  <div class="data-table" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{background:'rgb(250, 250, 250)'}" :data="tableData" :row-style="{ height: '57px' }"
              class="table-height"
              >
      <el-table-column prop="hardwareVersion" :label="$t('ProductManage.ModelManage.hardwareVersionNumber')"
      ></el-table-column>
      <el-table-column prop="softwareVersion" :label="$t('ProductManage.ModelManage.hardwareVersionNumberFirst4')"
      ></el-table-column>
      <el-table-column v-if="isMotorTable" prop="parameterVersion"
                       :label=" $t('ProductManage.ModelManage.parameterVersionNumber')"
      ></el-table-column>
      <el-table-column v-if="isMotorTable" prop="motorType" :label="$t('ProductManage.ModelManage.motorType')"
      ></el-table-column>
      <el-table-column prop="model" :label="$t('ProductManage.ModelManage.model')"
      ></el-table-column>
      <el-table-column prop="protocolType" :label="$t('ProductManage.ModelManage.protocolType')"
      ></el-table-column>
      <el-table-column prop="description" :label="$t('ProductManage.ModelManage.description')"
      ></el-table-column>
      <el-table-column :label="$t('ProductManage.ModelManage.operation')">
        <template v-slot="scope">
          <el-button link @click="editMotor(scope.row)"><span
              class="link-text-style">{{ $t('ProductManage.ModelManage.edit') }}</span></el-button>
          <el-popconfirm :title="$t('ProductManage.ModelManage.sureDelete')" placement="top"
                         @confirm="deleteMotor(scope.row)"
                         :confirm-button-text="$t('ProductManage.ModelManage.confirm')"
                         :cancel-button-text=" $t('ProductManage.ModelManage.cancel')"
                         confirm-button-type="success" width="250">
            <template #reference>
              <el-button link><span class="link-text-delete">{{ $t('ProductManage.ModelManage.delete') }}</span>
              </el-button>
            </template>
          </el-popconfirm>

        </template>
      </el-table-column>

      <template v-slot:empty>
        <EmptySlot/>
      </template>

    </el-table>
  </div>

  <!--编辑弹框-->
  <el-dialog
      style="text-align: left;"
      :title="$t('ProductManage.ModelManage.edit')"
      v-model="addDialogVisible"
      width="500"
      @close="handleDialogClose"
  >
    <hr class="top-separator"/>

    <!-- 弹窗内容 -->
    <el-form :model="motorForm" ref="motorForm" :rules="motorFormRules" label-width="150px" label-position="top">
      <el-form-item :label="$t('ProductManage.ModelManage.hardwareVersionNumber')" prop="hardwareVersion">
        <el-input v-model="motorForm.hardwareVersion"
                  :placeholder="$t('ProductManage.ModelManage.hardwareVersionPlaceholder')"></el-input>
      </el-form-item>
      <el-form-item v-if="isMotorTable" label="" prop="versionType">
        <el-radio-group v-model="motorForm.versionType" disabled>
          <el-radio :label="0"> {{ $t('ProductManage.ModelManage.hardwareVersion') }}</el-radio>
          <el-radio :label="1"> {{ $t('ProductManage.ModelManage.parameterVersion') }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 软件版本对应的输入框 -->
      <el-form-item :label="$t('ProductManage.ModelManage.hardwareVersionNumberFirst4')" prop="softwareVersion"
                    v-if="motorForm.versionType === 0 ">
        <el-input v-model="motorForm.softwareVersion"
                  :placeholder="$t('ProductManage.ModelManage.hardwareVersionNumberFirst4Placeholder')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('ProductManage.ModelManage.parameterVersionNumber')" prop="parameterVersion"
                    v-if="motorForm.versionType === 1 ">
        <el-input v-model="motorForm.parameterVersion"
                  :placeholder="$t('ProductManage.ModelManage.parameterVersionNumber')"></el-input>
      </el-form-item>

      <el-form-item v-if="isMotorTable" prop="motorType">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('ProductManage.ModelManage.motorType') }}</label>
        <el-select v-model="motorForm.motorType" :placeholder="$t('ProductManage.ModelManage.motorTypePlaceholder')"
                   style="width: 100%;" filterable>
          <el-option :label="$t('ProductManage.centerMotor')" :value="0"></el-option>
          <el-option :label="$t('ProductDiagnosis.wheelMotor')" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('ProductManage.ModelManage.model')" prop="model">
        <el-input v-model="motorForm.model" :placeholder="$t('ProductManage.ModelManage.modelPlaceholder')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('ProductManage.ModelManage.protocolType')" prop="protocolType">
        <el-radio-group v-model="motorForm.protocolType"
                        :placeholder="$t('ProductManage.ModelManage.protocolTypePlaceholder')" disabled>
          <el-radio :label="0">UART</el-radio>
          <el-radio :label="1">CAN</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('ProductManage.ModelManage.description')" prop="description">
        <el-input type="textarea" :rows="3" v-model="motorForm.description"
                  :placeholder="$t('ProductManage.ModelManage.descriptionPlaceholder')"></el-input>
      </el-form-item>
    </el-form>
    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="addDialogVisible = false" class="custom-reset-button">
        {{ $t('ProductManage.ModelManage.cancel') }}
      </el-button>
      <el-button class="button-background-color" @click="saveMotorData" :loading="isButtonLoading">{{
          $t('ProductManage.ModelManage.confirm')
        }}
      </el-button>
    </div>
  </el-dialog>

</template>
<script>
import {showProductModels, updateProductModels} from "@/api/api";
import resizeMixin from '@/mixins/resizeMixin';
import dialogMixin from '@/mixins/dialogMixin';
import EmptySlot from "@/components/EmptySlot.vue";

export default {
  components: {EmptySlot},
  emits: ['editMotor', 'deleteMotor'], // 在这里声明自定义事件
  mixins: [resizeMixin, dialogMixin],
  data() {
    return {
      addDialogVisible: false,
      motorForm: {
        motorId: null,
        hardwareVersion: '',
        softwareVersion: '',
        motorType: '',
        model: '',
        description: '',
        versionType: 0, // 设置默认值为 "软件版本"
        protocolType: '',
        softwareOrParameterVersion: '',
      },
      motorFormRules: {
        hardwareVersion: [
          {required: true, message: this.$t('ProductManage.hardwareVersionRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.hardwareVersionPattern'),
            trigger: 'blur',
          },
        ],
        softwareVersion: [
          {required: true, message: this.$t('ProductManage.softwareVersionRequired'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]{4}$/,
            message: this.$t('ProductManage.softwareVersionPattern'),
            trigger: 'blur',
          },
        ],
        motorType: [
          {required: true, message: this.$t('ProductManage.motorTypeRequired'), trigger: 'change'},
        ],
        model: [
          {required: true, message: this.$t('ProductManage.pleaseEnterModel'), trigger: 'change'},
          {
            pattern: /^[A-Za-z0-9_.-]+$/,
            message: this.$t('ProductManage.modelPattern'),
            trigger: 'blur',
          },
        ],
        protocolType: [
          {required: true, message: this.$t('ProductManage.protocolTypeRequired'), trigger: 'change'},
        ],
      },
    };
  },
  props: {
    isMotorTable: {
      type: Boolean,
      default: false
    },
    tableData: Array, // 电机数据
    loadData: {
      type: Function,
      required: true,
    },
    search: {
      type: Function,
      required: true,
    },
    loading: Boolean,  // 接收 loading 状态
    searchParams: Object,
  },
  methods: {
    handleDialogClose() {
      this.resetForm();
    },
    async editMotor(motor) {
      this.addDialogVisible = true;
      this.motorForm.motorId = motor.motor_id;
      try {
        const motorId = motor.motor_id;
        const motorInformation = await showProductModels(motorId);
        this.motorForm.motorId = motorInformation.data.id;
        this.motorForm.hardwareVersion = motorInformation.data.hardware_version;
        this.motorForm.softwareVersion = motorInformation.data.software_version;
        this.motorForm.motorType = motorInformation.data.motor_type;
        this.motorForm.model = motorInformation.data.code;
        this.motorForm.protocolType = motorInformation.data.protocol_type;
        this.motorForm.description = motorInformation.data.desc;
        this.motorForm.parameterVersion = motorInformation.data.config_version;
        this.motorForm.versionType = motorInformation.data.version_type;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.ConfigManage.editFailed'));
      }
    },
    deleteMotor(motor) {
      this.$emit('delete-motor', motor);
    },
    resetForm() {
      this.$refs.motorForm.resetFields();
    },
    async saveMotorData() {
      await this.$refs.motorForm.validate(async (valid) => {
        if (valid) {
          await this.handleButtonAction(async () => {

            // 检查是否已加载编辑数据
            if (!this.motorForm) {
              this.$message.error(this.$t('ProductManage.dataNotLoaded'));
              return;
            }
            // 调用更新接口，传递 motor_id 和编辑后的 formData
            const motor_id = this.motorForm.motorId; // 请替换成你实际的 motor_id
            const formData = {
              hardware_version: this.motorForm.hardwareVersion,
              software_version: this.motorForm.softwareVersion,
              motor_type: this.motorForm.motorType,
              code: this.motorForm.model,
              desc: this.motorForm.description,
              config_version: this.motorForm.parameterVersion,
            };

            await updateProductModels(motor_id, formData);
            // 数据保存成功后，关闭编辑弹框
            this.addDialogVisible = false;
            await this.search(this.searchParams);
            this.$message.success(this.$t('ApplyAfterSales.updateSuccess'));
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.data-table {
  margin: 0 12px -5px 0; /* 设置默认的左右间隔 */

  @media (max-width: 768px) {
    margin: -5px 12px -5px 0;/* 在小屏幕下减小间隔 */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    margin: -5px 12px -5px 0; /* 在中等屏幕下增加间隔 */
  }
}

/* 去除弹框内容的内边距 */
:deep(.el-dialog__body) {
  padding: 20px 15px 5px 20px;
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep(.el-dialog) {
  padding: 0; /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

:deep(.el-form-item__label) {
  margin-bottom: 0; /* 减少label下方的外边距 */
  padding-bottom: 0; /* 减少label的内边距 */
}


</style>