<template>
  <div class="search">
    <el-input v-model="hardwareVersion" :placeholder="$t('ProductManage.ModelManage.hardwareVersionPlaceholder')" class="search-input"
              :style="{ width: '350px' }" clearable/>
    <el-button @click="search" class="custom-reset-button">
      <el-icon>
        <Search/>
      </el-icon>
    </el-button>
  </div>
</template>

<script>
import {Search} from "@element-plus/icons-vue";

export default {
  components: {Search},
  data() {
    return {
      hardwareVersion: '',
    };
  },
  methods: {
    search() {
      let searchCondition = {};
      if (this.hardwareVersion) {
        searchCondition["hardware_version-like"] = this.hardwareVersion;
      }
      this.$emit('search', searchCondition);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
}

.search-input {
  margin-right: 0;
}
</style>