<template>
  <div class="distance-left">
    <div class="search-button-inline">
      <SearchBar @search="search"/>
      <AddButton :loadData="loadData" :isMotorTable="false"/>
    </div>
    <MotorTable :tableData="tableData" @delete-motor="deleteDashboard"
                    :loadData="loadData" :loading="loading" :search="search" :searchParams="searchParams" :isMotorTable="false"/>

    <pagination-bar :totalRecords="totalRecords" :current-page="currentPage" :page-size="pageSize"
                    @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>
  </div>
</template>

<script>
import SearchBar from '@/views/ProductManage/ModelManagement/components/MotorPageComponent/SearchBar.vue';
import AddButton from '@/views/ProductManage/ModelManagement/components/MotorPageComponent/AddButton.vue';

import PaginationBar from '@/components/PaginationBar.vue';
import {deleteProductModels, getProductModels} from "@/api/api";
import MyMixin from "@/mixins/Mixin";
import MotorTable from '@/views/ProductManage/ModelManagement/components/MotorPageComponent/MotorTable.vue';

export default {
  mixins: [MyMixin],
  components: {
    MotorTable,
    SearchBar,
    AddButton,
    PaginationBar,
  },
  data() {
    return {
      tableData: [], // 仪表盘数据
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      isSearching: false,
      searchParams: null,
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.search(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        this.$message.error(this.$t('ProductManage.loadDataFailed'));
      }
    },
    async handlePageSizeChange(newPageSize) {
      // 异步操作的处理逻辑
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.search(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        this.$message.error(this.$t('ProductManage.dataNotLoaded'));
      }
    },

    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        const data = await getProductModels(page, pageSize, {"type-eq": 1});

        // 将从服务器获取的数据进行字段重命名
        const filteredData = data.data.filter(item => item.type === 1);

        this.tableData = filteredData.map(item => ({

          motor_id: item.id,
          hardwareVersion: item.hardware_version,
          softwareVersion: item.software_version, // 根据值映射中文名称
          parameterVersion: item.config_version,
          motorType: this.mapMotorTypeToString(item.motor_type),
          motorTypeNum: item.motor_type,

          model: item.code,
          protocolType: this.mapProtocolTypeToString(item.protocol_type),
          protocolTypeNum: item.protocol_type,

          description: item.desc,
          versionType: item.version_type,
        }));

        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta
            .per_page === 'string' ? parseInt(data.meta
            .per_page, 10) : data.meta
            .per_page;
      } catch (error) {
        this.$message.error(this.$t('ProductManage.dataNotLoaded'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    async search(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      // 直接在 searchParams 中添加 type-eq 参数
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        const response = await getProductModels(page, pageSize, {"type-eq": 1, ...searchParams});
        // 在这里进行数据转换
        this.tableData = response.data.map(item => ({

          motor_id: item.id,
          hardwareVersion: item.hardware_version,
          softwareVersion: item.software_version, // 根据值映射中文名称
          parameterVersion: item.config_version,
          motorType: this.mapMotorTypeToString(item.motor_type),
          motorTypeNum: item.motor_type,
          model: item.code,
          protocolType: this.mapProtocolTypeToString(item.protocol_type),
          protocolTypeNum: item.protocol_type,
          description: item.desc,
          versionType: item.version_type,
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);

      } catch (error) {
        this.$message.error(this.$t('ProductManage.searchFailed'));
      } finally {
        this.loading = false; // 加载结束
      }
    },

    addMotor() {
      // 处理新增电机逻辑
    },

    async deleteDashboard(dashboard) {
      try {
        const response = await deleteProductModels(dashboard.motor_id);
        if (response.code === -1) {
          // 如果后端返回的code是-1，显示错误消息
          this.$message.error(this.$t('ProductManage.deleteFailed'));

        } else {
          // 如果删除成功，重新加载数据
          await this.loadData();
          this.$message.success(this.$t('ProductManage.deleteSuccess'));
        }
      } catch (error) {
        this.$message.error(this.$t('ProductManage.deleteError'));
      }
    }

  },
};
</script>
<style scoped>
.search-button-inline {
  margin-top: -10px;
}

.distance-left {
  margin-left: 0;
}
</style>
